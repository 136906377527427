<template>
  <v-container class="pa-0" fluid>
    <kurcc-inspection-list :key="inspectionListComponentKey" :filter="tabFilter" @change="reloadInspectionList"/>
  </v-container>
</template>

<script>
export default {
  name: 'KurccTodayPage',
  components: {
    KurccInspectionList: () => import('@/modules/app/components/KurccInspectionList')
  },
  data () {
    return {
      inspectionListComponentKey: 0
    }
  },
  computed: {
    tabFilter () {
      const filter = ['is_today=1', `inspector_id=${this.$auth.user().id}`]
      if (this.$route.query.tab !== 'inspections') {
        filter.push(`status=${this.$route.query.tab}`)
      } else {
        filter.push('status=upcoming')
      }
      return filter
    }
  },
  watch: {
    $route: function () {
      this.reloadInspectionList()
    }
  },
  methods: {
    reloadInspectionList () {
      this.inspectionListComponentKey++
    }
  }
}
</script>
